import { DSGrid, DSGridBlock } from '@/components/ds/Grid/Grid'
import { Header } from '@/components/elements/header/Header'
import { Footer } from '@/components/elements/new-footer/Footer'
import { DSFlex, DSSpacer, DSText } from '@zoe/ds-web'
import Head from 'next/head'

export const AppPage = () => {
  return (
    <>
      <Head>
        <title>ZOE - Download the ZOE app</title>
        <link rel="icon" href="/favicon.png" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>

      <Header />

      <DSGrid>
        <DSSpacer direction="vertical" size={144} />
        <DSGridBlock textAlign="center">
          <DSText as="h1" variant="fluid-heading-900">
            Download the ZOE App
          </DSText>
        </DSGridBlock>
        <DSSpacer direction="vertical" size={72} />
        <DSGridBlock breakConfig={{ s: { colSpan: 2 }, m: { colSpan: 3 }, l: { colSpan: 6 }, xl: { colSpan: 6 } }}>
          <DSFlex direction="column" alignItems="center">
            <a href="https://apps.apple.com/us/app/zoe-insights/id1471632228" target="_blank">
              <img src="/assets/img/app/AppStore.png" alt="App Store app link" width={300} />
            </a>
          </DSFlex>
          <DSSpacer direction="vertical" size={40} />
        </DSGridBlock>
        <DSGridBlock breakConfig={{ s: { colSpan: 2 }, m: { colSpan: 3 }, l: { colSpan: 6 }, xl: { colSpan: 6 } }}>
          <DSFlex direction="column" alignItems="center">
            <a href="https://play.google.com/store/apps/details?id=com.joinzoe.results" target="_blank">
              <img src="/assets/img/app/PlayStore.png" alt="Play Store app link" width={300} />
            </a>
          </DSFlex>
          <DSSpacer direction="vertical" size={40} />
        </DSGridBlock>
        <DSSpacer direction="vertical" size={72} />
      </DSGrid>
      <Footer showPreFooter />
    </>
  )
}
